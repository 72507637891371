import React from 'react';
import slugify from 'react-slugify';
import Img from 'gatsby-image';

import { Props, Universities } from './UniversityPartners.interface';

import s from './UniversityPartners.scss';

export default ({ location, universities }: Props): JSX.Element => (
    <>
        <h3 className={s.university__partners___heading}>
            {location} Partner Universities
        </h3>

        <div className={`${s.university__partners} ${s[ `university__partners___${location}` ]}`}>
            {universities.map(({ node }: Universities) => (
                <div
                    key={`universities-${location}-${slugify(node.name)}`}
                    className={s.university__partner}
                >
                    <Img
                        fixed={node.logo.localFile.childImageSharp.fixed}
                        className={s.university__partner__img}
                        title={`${node.name} logo`}
                        alt={`${node.name} logo`}
                    />
                </div>
            ))}
        </div>
    </>
);
