import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusScholarshipWinner, AllDirectusScholarship, AllDirectusUniversity } from './scholarship.interface';

type Scholarship = AllDirectusScholarshipWinner & AllDirectusScholarship & AllDirectusUniversity;

export default (): any => {
    const {
        allDirectusScholarshipWinner,
        allDirectusScholarship,
        allDirectusUniversity,
    } = useStaticQuery<Scholarship>(graphql`
    query {
      allDirectusScholarshipWinner {
        edges {
          node {
            directusId
            name
            course_name
            university
            year
          }
        }
      }
      allDirectusScholarship {
        edges {
          node {
            directusId
            title
            content
            button_text
          }
        }
      }
      allDirectusUniversity {
        edges {
          node {
            directusId
            name
            location
            logo {
              data {
                full_url
              }
              width
              height
              localFile {
                childImageSharp {
                  fixed(width: 120) {
                    ...GatsbyImageSharpFixed_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

    return {
        node: allDirectusScholarship.edges[ 0 ].node,
        winners: allDirectusScholarshipWinner.edges,
        universities: allDirectusUniversity.edges,
    };
};
