import React from 'react';

import Button from 'components/common/button/Button';
import Markdown from 'components/common/markdown/Markdown';
import PageTitle from 'components/directus/page-title/PageTitle';
import ScholarshipWinners from 'components/directus/scholarship-winners/ScholarshipWinners';
import UniversityPartners from 'components/directus/university-partners/UniversityPartners';
import scholarshipHook from 'hooks/scholarship/scholarship.hook';
import { Props } from './Scholarship.interface';

import s from './Scholarship.scss';

export default ({ slug }: Props): JSX.Element => {
    const { node, winners, universities } = scholarshipHook();
    const getUniversitiesByLocation = (location: string) => (
        universities.filter(university => university.node.location === location)
    );

    return (
        <>
            <div className={s.grid}>
                <div>
                    <div className={s.pageTitle}>
                        <PageTitle slug={slug}/>
                    </div>

                    <Markdown className={s.scholarship__content} source={node.content} container />

                    <div className={s.scholarship__container}>
                        <Button
                            size="large"
                            role="secondary"
                            to="mailto:funding@rapidformations.co.uk"
                            className={s.scholarship__container___sendButton}>
                            {node.button_text}
                        </Button>
                    </div>

                    <ScholarshipWinners winners={winners} />

                    <div className={s.universityPartnersUs}>
                        <UniversityPartners location="US" universities={getUniversitiesByLocation('US')} />
                    </div>
                </div>
                <div>
                    <UniversityPartners location="UK" universities={getUniversitiesByLocation('UK')} />
                </div>
            </div>
        </>
    );
};
